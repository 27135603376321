<template>
  <v-container fluid class="mt-8">
        <v-row v-if="permissions == 'admin'">
            <v-col cols="3">
                <multiselect
                    closeOnSelect
                    v-model="searchByArea"
                    :options="handledAreaList"
                    group-label="region"
                    group-values="location"
                    :multiple="false"
                    :group-select="false"
                    :placeholder="$t('common.area')"
                    track-by="alias"
                    :label="(lang == 'tc')? 'nameZh' : 'nameEng'"
                >
                    <span slot="noResult">{{$t('global_alert.area_no_result')}}</span>
                </multiselect>
            </v-col>
            <v-col cols="ma-0 px-0">
                <v-btn dark @click="searchByArea = null">{{$t('common.clear_area')}}</v-btn>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    prepend-icon="mdi-magnify"
                    v-model="searchByName"
                    :label="$t('merchant.search_name')"
                    dense
                    clearable
                />
            </v-col>
            <v-col cols="3">
                <v-text-field
                    prepend-icon="mdi-magnify"
                    v-model="searchByEmail"
                    :label="$t('merchant.search_email')"
                    dense
                    clearable
                />
            </v-col>
            <v-col  cols="2">
                <v-btn dark :loading="excelLoading">
                <JsonExcel
                    name="PlaysmartMerchantList.xls"
                    :fetch="downoloadExcel"
                >
                    {{ $t('merchant.export_data') }}
                </JsonExcel>
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            ref="myTable"
            class="elevation-4"
            :headers="i18nHeader"
            :loading="loading"
            :items="merchantsList"
            :options.sync="options"
            :custom-sort="customSort"
            :server-items-length="totalMerchant"
            :footer-props="{'items-per-page-options': [5, 10, 20, 30, 50, 100]}"
            mobile-breakpoint=0
        >
            <template v-slot:item="{ item, index }">
                <tr>
                    <td>{{ item.name }} </td>
                    <td v-text="item.email" />
                    <td v-text="item.phone" />
                    <td>{{ (item.category)? item.category[(lang == 'tc')? 'nameZh' : 'nameEng' ] : item.category }} </td>
                    <td>{{ handledMerchantPlan(item.subscription) }} </td>
                    <td> {{$moment(item.createdAt).format('YYYY/MM/DD HH:mm')}}</td>
                    <td> {{$moment(item.endDate).format('YYYY/MM/DD HH:mm')}}</td>
                    <td>
                        <v-btn :disabled="permissions != 'admin'"  small :color="(item.status === 'active')? 'success': 'error'" @click="changeStatus(item, index)"> <v-icon left>mdi-{{(item.status === 'active')? 'check': 'close'}}</v-icon> {{showStatus(item.status)}}</v-btn>
                    </td>
                    <td>
                        <v-icon @click="edit(item)"> mdi-pencil</v-icon>
                    </td>
                    <td align="center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="filterItem(item._id, 'service')">mdi-order-bool-ascending-variant</v-icon>
                            </template>
                            <span>{{$t('page_name.service')}}</span>
                        </v-tooltip>
                        {{'|'}}
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="filterItem(item._id, 'package')">mdi-package-variant-closed</v-icon>
                            </template>
                            <span>{{$t('page_name.package')}}</span>
                        </v-tooltip>
                        {{'|'}}
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="filterItem(item._id, 'order')">mdi-bookmark-outline</v-icon>
                            </template>
                            <span>{{$t('page_name.order')}}</span>
                        </v-tooltip>
                        {{'|'}}
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="filterItem(item._id, 'orderPackage')">mdi-package</v-icon>
                            </template>
                            <span>{{$t('page_name.package_order')}}</span>
                        </v-tooltip>
                        {{'|'}}
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="filterItem(item._id, 'teacher')">mdi-school</v-icon>
                            </template>
                            <span>{{$t('page_name.teacher')}}</span>
                        </v-tooltip>
                    </td>
                </tr>
                </template>
        </v-data-table>
        <FormDialog ref="dialog" :categoryList="categoryList" :paymentMethodList="paymentMethodList" :merchantPlanList="merchantPlanList" @refresh="getAllMerchant" :areasList="handledAreaList"/>
        <confirmDialog ref="confirmDialog"/>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import FormDialog from "@/views/Merchants/DetailDialog"
import confirmDialog from "@/components/ConfirmDialog"
import JsonExcel                from "vue-json-excel"
import api from '@/api'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
    components:{
        FormDialog,
        confirmDialog,
        JsonExcel,
        Multiselect
    },
    mounted(){
        // this.getAllMerchant()
        this.getAllCategories()
        this.getAllPaymentMethod()
        this.getAllMerchantPlan()
        this.getAllAreas()
    },
    computed:{
        ...mapState(['lang', 'permissions']),
        i18nHeader(){
            let result = this.headers

            result.map( item => {
                item.text = this.$t(item.i18n)
            })

            return result
        },
        handledAreaList(){
            let result = []
            if(this.areasList && this.areasList != ''){
                let areaArr = this.areasList['area']
                let location = this.areasList['subArea']

                areaArr.map( item => {
                    result.push({
                        'region': item[(this.lang == 'tc')? 'nameZh' : 'nameEng'],
                        'location': location[item.alias]
                    })
                })
            }
            return result
        }
    },
    watch: {
        options: {
            handler () {
                this.getAllMerchant()
            }
        },
        searchByEmail: {
            handler (){
                this.getAllMerchant()
            }
        },
        searchByArea: {
            handler (){
                this.getAllMerchant()
            }
        },
        searchByName: {
            handler (){
                this.getAllMerchant()
            }
        },
        lang:{
            handler (){
                this.handledMerchantPlan()
            }
        }
    },
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async getAllMerchant() {
            this.loading = true
            try {
                var {sortBy, sortDesc, page, itemsPerPage} = this.options
                //let order = (sortDesc[0])? -1 : 1
                let params = {
                    page: page,
                    perPage: itemsPerPage,
                    sort: "endDate",
                    order: -1,
                }

                if(this.selctedUserRole != '') Object.assign( params ,{role: this.selctedUserRole} )
                if(this.searchByName != '') Object.assign( params ,{name: this.searchByName} )
                if(this.searchByEmail != '') Object.assign( params ,{email: this.searchByEmail} )
                if(this.searchByArea) Object.assign( params ,{area: this.searchByArea.alias} )

                let result = await api.get('/merchants', {
                    params
                })

                if(result){
                    this.merchantsList = result.data.docs //user list
                    this.totalMerchant = result.data.totalDocs

                    if (sortBy.length === 1 || sortDesc.length === 1) {
                        this.merchantsList = this.merchantsList.sort((a, b) => {
                            const sortA = a[sortBy[0]]
                            const sortB = b[sortBy[0]]

                            if (sortDesc[0]) {
                                if (sortA < sortB) return 1
                                if (sortA > sortB) return -1
                                return 0
                            } else {
                                if (sortA < sortB) return -1
                                if (sortA > sortB) return 1
                                return 0
                            }
                        })
                    }
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
            this.loading = false
        },
        async getAllCategories() {
            let apiTarget = (this.permissions == 'admin')? '/category' : '/category/published'

            try{
                let result = await api.get(apiTarget)
                if(result) this.categoryList = result.data
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllPaymentMethod() {
            let apiTarget = (this.permissions == 'admin')? '/paymentMethod' : '/paymentMethod/published'

            try{
                let result = await api.get(apiTarget)
                if(result) this.paymentMethodList = result.data
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllMerchantPlan() {
            let apiTarget = (this.permissions == 'admin')? '/merchantPlan' : '/merchantPlan/published'

            try{
                let result = await api.get(apiTarget)

                if(result){
                    this.merchantPlanList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllAreas() {
            try{
                let result = await api.get('/setting/areas')

                if(result){
                    this.areasList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async changeStatus(item, index){
            let action = await this.$refs.confirmDialog.open({
                title  : this.$t('merchant.update_status'),
                content: this.$t('merchant.qa_update_status') + `"${item.name}"` + `${(item.status === 'active')? this.$t('merchant.qa_update_status_ban') : this.$t('merchant.qa_update_status_active')}?`,
                })

            if (action) {
                let result = false

                item.status = (item.status == 'active')? 'inactive' : 'active'

                try {
                    result = await api.patch('/merchants/'+item._id, item)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }

                if (result) {
                    this.merchantsList[index].status = item.status
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.success_update_status'),
                        icon   : 'mdi-check',
                    })
                }

                this.getAllMerchant()
            }
        },
        handledMerchantPlan(planID){
            let result = planID

            if(planID && this.merchantPlanList.length > 0){
                let found = this.merchantPlanList.find(item => item._id == planID)
                if(found) result = found[(this.lang == 'tc')? 'nameZh' : 'nameEng' ]
            }

            return result
        },
        handledCategoryPlan(planID){
            let result = planID

            if(planID && this.categoryList.length > 0){
                let found = this.categoryList.find(item => item._id == planID)
                if(found) result = found[(this.lang == 'tc')? 'nameZh' : 'nameEng' ]
            }

            return result
        },
        filterItem(id, to){
            if(to == 'service')
            {
                this.$router.push({ path: '/service', query: { id: id } })
            }
            else if(to == 'package')
            {
                this.$router.push({ path: '/package', query: { id: id } })
            }
            else if(to == 'order')
            {
                this.$router.push({ path: '/order/service', query: { id: id } })
            }
            else if(to == 'orderPackage')
            {
                this.$router.push({ path: '/order/package', query: { id: id } })
            }
            else if(to == 'teacher')
            {
                this.$router.push({ path: '/teacher', query: { id: id } })
            }
        },
        create(){
            this.$refs.dialog.open('create')
        },
        edit(item){
            this.$refs.dialog.open('edit', item)
        },
        showStatus(status){
            return (status === 'active')? this.$t('common.enabled'):this.$t('common.disabled');
        },
        customSort(
            items,
            sortBy,
            sortDesc,
            locale,
            customSorters
            ) {
                /*
                :sort-by="['name','email','phone','createdAt', 'endAt', 'status']"
                :sort-desc="[false, true]"
                */
                console.log(sortBy);
               console.log(customSorters);
               console.log(sortDesc);
            return items.sort((a, b) => a.name.localeCompare(b.name, locale));
                
        },
        async downoloadExcel () {
            this.excelLoading = true
            let resultArr = []
            let tmpObj = {}

            try {

                var result = await api.get('/merchants/all')
                console.log(result);

                result = result.data

                result.forEach(item => {
                tmpObj = {
                    "id": item._id,
                    "name": item.name ?? "",
                    "email": item.email ?? "",
                    "phone": item.phone ?? "",
                    "area": item.area ?? "",
                    "photoNo": item.image == null ? 0 : item.image.length,
                    "category": this.handledCategoryPlan(item.category),
                    "subscription": this.handledMerchantPlan(item.subscription),
                    "createdAt": this.$moment(item.createdAt).format('YYYY/MM/DD HH:mm'),
                    "endDate": this.$moment(item.endDate).format('YYYY/MM/DD HH:mm'),
                    "status": item.status ?? "",
                }
                

                    if (tmpObj != '') resultArr.push(tmpObj)
                });
                } catch (error) {
                    console.log(error);
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }
                this.excelLoading = false
                return resultArr;
            },
    },
    data: () => ({
        headers: [
            {i18n: 'common.user_name',value: 'name', align: "center"},
            {i18n: 'common.email',value: 'email', align: "center"},
            {i18n: 'common.phone',value: 'phone', align: "center"},
            {i18n: 'merchant.categories',value: 'category', align: "center"},
            {i18n: 'merchant.subscription',value: 'subscription', align: "center"},
            {i18n: 'common.created_at', value: 'createdAt', align: "center"},
            {i18n: 'merchant.end_time', value: 'endDate', align: "center"},
            {i18n: 'common.status', value: 'status', align: "center"},
            {i18n: 'common.edit', value: '', sortable: false , align: "center"},
            {i18n: 'common.view', value: '', sortable: false, align: "center"},
        ],
        json_meta: [
            [ { key: "charset", value: "utf-8", },],
        ],
        searchByName: null,
        searchByEmail: null,
        searchByArea: null,
        merchantsList:[],
        categoryList:[],
        paymentMethodList: [],
        merchantPlanList: [],
        areasList:[],
        loading: false,
        createUserFormValid: true,
        editUserFormValid: true,
        options: {},
        totalMerchant: 0,
        excelLoading      : false
    })
}
</script>

