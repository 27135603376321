<template>
    <v-container>
        <v-dialog v-model="dialog" persistent max-width="1200">
            <v-toolbar dark>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('merchant.' + action) }}</v-toolbar-title>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="userForm" v-model="formValid" lazy-validation>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-text-field v-model="merchantObj.name" :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('merchant.name')" />
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-text-field v-model="merchantObj.phone" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9]{8}$/.test(v) || $t('common.valid_phone')
                                    ]" :label="$t('merchant.phone')" />
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-text-field v-model="merchantObj.email" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /.+@.+\..+/.test(v) || $t('common.valid_email')
                                    ]" :label="$t('merchant.email')" :disabled="action != 'create'" />
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-text-field v-model="merchantObj.open" :rules="[
                                        v => !!v || $t('common.required')
                                    ]" :label="$t('merchant.open')" />
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-select v-model="merchantObj.category" :items="categoryList" item-value="_id"
                                        :item-text="(lang == 'tc') ? 'nameZh' : 'nameEng'"
                                        :label="$t('merchant.categories')"
                                        :rules="[v => v.length > 0 || $t('common.required')]" />
                                </v-col>
                                <v-col cols="6" class="py-0" v-if="permissions == 'admin'">
                                    <v-select :items="merchantPlanList" v-model="merchantObj.subscription" item-value="_id"
                                        :item-text="(lang == 'tc') ? 'nameZh' : 'nameEng'"
                                        :label="$t('merchant.subscription')" />
                                </v-col>
                                <v-col cols="6" class="py-0" v-if="permissions == 'admin'">
                                    <v-menu v-model="endDateDateDialog" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="merchantObj.endDate" :label="$t('merchant.end_time')"
                                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                                        </template>
                                        <v-date-picker v-model="merchantObj.endDate" @input="endDateDateDialog = false" />
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-textarea v-model="merchantObj.address" :label="$t('common.address')" rows="2" />
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-textarea v-model="merchantObj.precautions" :label="$t('merchant.remark')" rows="2" />
                                </v-col>
                                <v-col cols="6">
                                    <multiselect v-model="merchantObj.area" :options="areasList" group-label="region"
                                        group-values="location" :multiple="false" :group-select="false"
                                        :placeholder="$t('common.area')" track-by="alias"
                                        :label="(lang == 'tc') ? 'nameZh' : 'nameEng'">
                                        <span slot="noResult">{{ $t('global_alert.area_no_result') }}</span>
                                    </multiselect>
                                </v-col>
                                <v-col cols="6" class="py-0" v-if="permissions == 'admin'">
                                    <v-select :items="paymentMethodList" v-model="merchantObj.paymentMethods"
                                        item-value="_id" :item-text="(lang == 'tc') ? 'nameZh' : 'nameEng'"
                                        :label="$t('merchant.payment_methods')" multiple @change="updatePaymentAccountArray"
                                        :rules="[v => v.length > 0 || $t('common.required')]" />
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-row>
                                        <v-col cols="6" class="pb-0" v-for="(item, key) in merchantObj.paymentAccount"
                                            :key="key">
                                            <v-card class="px-3 mb-7">
                                                <v-card-title>
                                                    {{ $t('merchant.payment_methods') + ' - ' + item.name }}
                                                    <v-spacer />
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-text-field v-model="item.accountInfo"
                                                        :label="$t('merchant.payment_methods_account')" />
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-text-field v-model="merchantObj.charge" :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('merchant.charge')" />
                                </v-col>

                                <v-col cols="12" class="py-0">
                                    <v-text-field v-model="merchantObj.website" :label="$t('merchant.website')"
                                        :rules="[v => /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(v) || $t('common.valid_url')]"
                                        hint="http://google.com" persistent-hint />
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-textarea v-model="merchantObj.slogan" :label="$t('merchant.slogan')" rows="2" />
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-textarea v-model="merchantObj.description" :label="$t('merchant.description')"
                                        rows="2" />
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-textarea v-model="merchantObj.facilities" :label="$t('merchant.facilities')"
                                        rows="2" />
                                </v-col>
                                <v-col cols="12">
                                    <v-card>
                                        <v-card-title>{{ $t('merchant.image') }}</v-card-title>
                                        <v-card-text>
                                            <draggable v-if="merchantObj.image" v-model="merchantObj.image"
                                                @start="drag = true" @end="drag = false" tag="div" class="row"
                                                draggable=".draggableImage">
                                                <v-col v-for="(image, index) in merchantObj.image" :key="image._id"
                                                    class="d-flex child-flex draggableImage" cols="2" style="height:235px">
                                                    <v-hover>
                                                        <template v-slot:default="{ hover }">
                                                            <v-card>
                                                                <v-img max-height="150"
                                                                    v-if="image.large && image.large.path"
                                                                    :src="image.large.path" aspect-ratio="1"
                                                                    class="grey lighten-2">
                                                                    <template v-slot:placeholder>
                                                                        <v-row class="fill-height ma-0" align="center"
                                                                            justify="center">
                                                                            <v-progress-circular indeterminate
                                                                                color="grey lighten-5" />
                                                                        </v-row>
                                                                    </template>
                                                                </v-img>
                                                                <v-fade-transition>
                                                                    <v-overlay v-if="hover" absolute color="#036358">
                                                                        drag to re-odering
                                                                    </v-overlay>
                                                                </v-fade-transition>
                                                                <v-card-actions>
                                                                    <v-btn v-if="permissions == 'admin'" style="z-index:999"
                                                                        icon @click="copyImage(index)">
                                                                        <v-icon>mdi-content-copy</v-icon>
                                                                    </v-btn>
                                                                    <v-spacer />
                                                                    <v-btn style="z-index:999" icon
                                                                        @click="removeImage(index)">
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </template>
                                                    </v-hover>
                                                </v-col>
                                            </draggable>
                                            <v-col cols="12" key="newImage">
                                                <v-card style="height:100%;">
                                                    <v-container>
                                                        <file-pond name="upload_image" ref="pond"
                                                            :label-idle="$t('img_label')"
                                                            accepted-file-types="image/jpeg, image/png"
                                                            :files="uploadImageFile" allow-multiple="false"
                                                            max-file-size="5MB" @processfile="removeFile" />
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <!-- <v-col cols="12">
                                    <v-card>
                                        <v-card-title>{{ $t('merchant.br') }}</v-card-title>
                                        <v-card-text>
                                            <v-img
                                                v-if="merchantObj.br && merchantObj.br.large && merchantObj.br.large.path"
                                                class="mx-auto"
                                                :src="merchantObj.br.large.path"
                                                max-width="500"
                                            />
                                            <file-pond
                                                v-else
                                                name="upload_br"
                                                ref="pond"
                                                :label-idle="$t('img_label')"
                                                accepted-file-types="image/jpeg, image/png"
                                                :files="uploadImageFile"
                                                max-file-size="5MB"
                                                v-on:removefile="merchantObj.br = ''"
                                            />
                                        </v-card-text>
                                        <v-card-actions v-if="merchantObj.br && merchantObj.br.large && merchantObj.br.large.path">
                                            <v-spacer/>
                                                <v-btn @click="merchantObj.br = ''" text>
                                                    <v-icon>mdi-pencil</v-icon>
                                                </v-btn>
                                            <v-spacer/>
                                        </v-card-actions>
                                    </v-card>
                                </v-col> -->

                                <v-col cols="12" class="py-0" v-if="action == 'edit' && permissions == 'admin'">
                                    <v-switch v-model="merchantObj.status" :label="showStatus(merchantObj.status)"
                                        true-value="active" false-value="inactive" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" v-if="action == 'edit'">
                                    <v-text-field :value="$moment(merchantObj.createdAt).format('YYYY/MM/DD HH:mm')"
                                        disabled :label="$t('common.created_at')" />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" @click="save(action)">{{ $t('common.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import api from '@/api'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import draggable from 'vuedraggable'

// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export default {
    components: {
        FilePond,
        Multiselect,
        draggable
    },
    mounted() {
        let self = this
        this.setOptions({
            server: {
                process: async function (fieldName, files, metadata, load, error, progress, abort) {
                    try {
                        let formData = new FormData()
                        formData.append('file', files)

                        let result = await api.post('/image', formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        })

                        if (result) {
                            self.merchantObj.image.push(result.data);
                        }
                        load(true)
                    } catch (err) {
                        console.log(err.message);
                        abort(true)
                    }
                }
            },
            imageResizeMode: 'contain',
        })
    },
    computed: {
        ...mapState(['lang', 'permissions'])
    },
    props: ['categoryList', 'paymentMethodList', 'merchantPlanList', 'areasList'],
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async open(action, item) {
            if (action == 'edit') {
                try {
                    let result = await api.get('/merchants/' + item._id)
                    this.merchantObj = result.data

                    if (this.merchantObj.category) this.merchantObj.category = this.merchantObj.category._id

                    if (this.merchantObj.paymentMethods) {
                        if (this.merchantObj.paymentAccount == '' && this.merchantObj.paymentAccount.length == 0) {
                            this.merchantObj.paymentMethods.map(item => {
                                this.merchantObj.paymentAccount.push(
                                    {
                                        paymentMethod: item._id,
                                        name: item[(this.lang == 'tc') ? 'nameZh' : 'nameEng'],
                                        accountInfo: ''
                                    }
                                )
                            })
                        } else {
                            this.merchantObj.paymentAccount.map((item, index) => {
                                this.merchantObj.paymentAccount[index].name = item.paymentMethod[(this.lang == 'tc') ? 'nameZh' : 'nameEng']
                            })
                        }
                    }

                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                let area = this.merchantObj.area

                this.areasList.map(item => {
                    item.location.map(lcationItem => {
                        if (lcationItem.alias === area) {
                            this.merchantObj.area = lcationItem
                        }
                    })
                })

                if (this.merchantObj.endDate) this.merchantObj.endDate = this.$moment(this.merchantObj.endDate).format('YYYY-MM-DD')
            }
            this.action = action
            this.dialog = true
        },
        async save() {
            let validated = this.$refs.userForm.validate()

            if (validated) {
                let result = false
                this.merchantObj.area = this.merchantObj.area['alias']

                try {
                    if (this.action == 'create') {
                        result = await api.post('/merchants', this.merchantObj)
                    } else {
                        result = await api.patch('/merchants/' + this.merchantObj._id, this.merchantObj)
                    }

                    if (result) {
                        this.resetForm()
                        this.dialog = false
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.success_update_merchant'),
                            icon: 'mdi-check',
                        })
                    }
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                    this.loading = false
                }

            }
        },
        showStatus(status) {
            return (status == 'active') ? this.$t('common.enabled') : this.$t('common.disabled')
        },
        formatTime(time) {
            let resultTime = time

            if (time) {
                resultTime = (time && this.$moment(time, 'HH:mm', true).isValid()) ? this.$moment(time, 'HH:mm').format('HHmm') : this.$moment(time, 'HHmm').format('HH:mm')
            }

            return resultTime
        },
        close() {
            this.resetForm()
            this.dialog = false
        },
        removeImage(index) {
            this.merchantObj.image.splice(index, 1)
        },
        copyImage(index) {
            var toCopy = this.merchantObj.image[index]["small"]["path"];
            navigator.clipboard.writeText(toCopy);
        },
        resetForm() {
            this.$emit('refresh')
            this.uploadImageFile = []
            this.paymentMethodArr = []
            this.merchantObj = {
                name: '',
                category: '',
                phone: '', //  /^[0-9]{8}$/
                email: '',
                area: '',
                address: '',
                precautions: '',
                charge: '$100/hour',
                open: '09:00 - 18:00',
                website: 'http://',
                slogan: '',
                description: '',
                facilities: '',
                paymentMethods: '',
                paymentAccount: [],
                subscription: '',
                image: [],
                endDate: null,
                status: 'active',
            }
        },
        updatePaymentAccountArray(event) {
            let tmpArray = []

            event.map((id, index) => {
                let fond = this.paymentMethodList.find(item => item._id == id)

                if (fond) {
                    let foundPaymentName = fond[(this.lang == 'tc') ? 'nameZh' : 'nameEng']
                    tmpArray.push({
                        paymentMethod: id,
                        name: foundPaymentName,
                        accountInfo: (this.merchantObj.paymentAccount[index] && this.merchantObj.paymentAccount[index].accountInfo) ? this.merchantObj.paymentAccount[index].accountInfo : ''
                    })
                }
            })

            this.merchantObj.paymentAccount = tmpArray
        },
        removeFile(error, file) {
            this.$refs.pond.removeFile(file)
        },
    },
    data: () => ({
        dialog: false,
        setOptions,
        uploadImageFile: [],
        paymentMethodArr: [{ paymentMethod: '', accountInfo: '' }],
        endDateDateDialog: false,
        merchantObj: {
            name: '',
            category: '',
            phone: '', //  /^[0-9]{8}$/
            email: '',
            area: '',
            address: '',
            precautions: '',
            charge: '$100/hour',
            open: '09:00 - 18:00',
            website: 'http://',
            slogan: '',
            description: '',
            facilities: '',
            paymentMethods: '',
            paymentAccount: [],
            subscription: '',
            image: [],
            endDate: null,
            status: 'active',
        },
        action: 'edit',
        formValid: true
    })
}
</script>